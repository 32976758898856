html, body, #root {
    margin: 0;
}
.greyBg {
    background: #f6f6f6;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
.width100p {
    width: 100%;
}
.centerText {
    text-align: center;
}
.audioButtonGroup {
    position: relative;
    min-height: 300px;
}
.volumeSlider {
    margin-right: 6px;
    padding: 20px 0 !important
}
.scrollableLink {
    max-width: calc(100% - 48px);
    white-space: nowrap;
    overflow-x: auto;
}